<template>
  <div class="wt-container pa-9 pa-md-6 pa-lg-9">
    <validation-observer ref="observer">
      <v-form @submit.prevent="createNewWorktime" ref="form">
        <v-container>
          <v-row>
            <v-col cols="12" class="mb-6">
              <h3 class="page-title font-weight-bold">新規勤怠の作成</h3>
              <span
                >日付・タスクを選択した後に勤怠情報を入力してください。</span
              >
            </v-col>
            <v-col cols="12">
              <div class="form-content">
                <v-row align="center">
                  <v-col cols="2" class="form-header text-right">月日</v-col>
                  <v-col cols="4">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date"
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="date"
                          rules="required"
                        >
                          <v-text-field
                            v-model="date"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                            readonly
                            autocomplete="off"
                            :error-messages="errors"
                            :error="errors.length !== 0"
                            :hide-details="errors.length === 0"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="date"
                        :day-format="formatDatePicker"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false">
                          キャンセル
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog.save(date); getTaskData()"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-row align="center">
                  <v-col cols="2" class="form-header text-right"
                    >対象タスク</v-col
                  >
                  <v-col cols="9" class="flex-grow-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="task"
                      rules="required"
                    >
                      <v-select
                        class="select"
                        placeholder="未選択"
                        outlined
                        dense
                        :items="allTasks"
                        item-text="name"
                        item-value="id"
                        :error-messages="errors"
                        :error="errors.length !== 0"
                        :hide-details="errors.length === 0"
                        v-model="formData.task_id"
                        :disabled="!date || loading.task"
                        :loading="loading.task"
                        @input="getTaskMembers"
                      >
                      </v-select>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row align="center">
                  <v-col cols="2" class="form-header text-right">対象者</v-col>
                  <v-col cols="9" class="flex-grow-1">
                    <validation-provider
                      v-slot="{ errors }"
                      name="members"
                      rules="required"
                    >
                      <v-autocomplete
                        outlined
                        chips
                        dense
                        label="未選択"
                        class="select"
                        deletable-chips
                        small-chips
                        :items="allUsers"
                        item-text="full_name"
                        item-value="id"
                        multiple
                        v-model="formData.taskMembers"
                        :error-messages="errors"
                        :error="errors.length !== 0"
                        :hide-details="errors.length === 0"
                        :disabled="!formData.task_id"
                        :loading="loading.member"
                      >
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row v-if="!createAttendance">
                  <v-col cols="12" class="text-center">
                    <v-btn
                      rounded
                      color="#4F55A7"
                      class="white--text px-8"
                      @click="createAttendances"
                    >
                      <v-icon size="20" color="white" class="mr-2" left
                        >$attendance</v-icon
                      >
                      勤怠を作成する
                    </v-btn>
                  </v-col>
                </v-row>

                <div v-if="createAttendance">
                  <div class="attendance-container py-10 mt-8">
                    <!-- <v-row align="center">
                  <v-col cols="12" class="text-heading-3">
                    勤怠ID: W12345678
                  </v-col>
                </v-row> -->

                    <v-row align="center">
                      <v-col cols="2" class="form-header-1 text-right"
                        >タイプ</v-col
                      >
                      <v-col cols="3">
                        <validation-provider
                          v-slot="{ errors }"
                          name="type_id"
                          rules="required"
                        >
                          <v-select
                            class="select"
                            placeholder="未選択"
                            outlined
                            dense
                            :items="clockType"
                            item-text="name"
                            item-value="value"
                            v-model="formData.type_id"
                            :error-messages="errors"
                            :error="errors.length !== 0"
                            :hide-details="errors.length === 0"
                          >
                          </v-select>
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="2" class="form-header-1 text-right"
                        >イン</v-col
                      >
                      <v-col cols="3">
                        <v-dialog
                          ref="modalIn"
                          v-model="modalIn"
                          :return-value.sync="timeIn"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <validation-provider
                              v-slot="{ errors }"
                              name="clock_in"
                              rules="required"
                            >
                              <v-text-field
                                v-model="timeIn"
                                dense
                                outlined
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="select"
                                :error-messages="errors"
                                :error="errors.length !== 0"
                                :hide-details="errors.length === 0"
                              ></v-text-field>
                            </validation-provider>
                          </template>
                          <v-time-picker
                            v-if="modalIn"
                            v-model="timeIn"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modalIn = false"
                            >
                              キャンセル
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.modalIn.save(timeIn)"
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="1" class="form-header-1 text-right"
                        >アウト</v-col
                      >
                      <v-col cols="3">
                        <v-dialog
                          ref="modalOut"
                          v-model="modalOut"
                          :return-value.sync="timeOut"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <validation-provider
                              v-slot="{ errors }"
                              name="clock_out"
                              rules="required"
                            >
                              <v-text-field
                                v-model="timeOut"
                                dense
                                outlined
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="select"
                                :error-messages="errors"
                                :error="errors.length !== 0"
                                :hide-details="errors.length === 0"
                              ></v-text-field>
                            </validation-provider>
                          </template>
                          <v-time-picker
                            v-if="modalOut"
                            v-model="timeOut"
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modalOut = false"
                            >
                              キャンセル
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.modalOut.save(timeOut)"
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>

                    <v-row align="center">
                      <v-col cols="2" class="form-header-1 text-right"
                        >昼休憩</v-col
                      >
                      <v-col cols="2" class="d-flex align-center">
                        <v-text-field
                          class="select"
                          dense
                          outlined
                          hide-details
                          v-model="formData.day_rest"
                          @keypress="isNumber"
                          autocomplete="off"
                        >
                        </v-text-field>
                        <span class="ml-4">分</span>
                      </v-col>

                      <v-col cols="1" class="form-header-1 text-right ml-7"
                        >夜休憩</v-col
                      >
                      <v-col cols="2" class="d-flex align-center">
                        <v-text-field
                          class="select"
                          dense
                          outlined
                          hide-details
                          v-model="formData.night_rest"
                          @keypress="isNumber"
                          autocomplete="off"
                        >
                        </v-text-field>
                        <span class="ml-4">分</span>
                      </v-col>

                      <v-col cols="auto" class="form-header-1 text-right ml-7"
                        >WTタグ</v-col
                      >
                      <v-col cols="3" class="d-flex align-center">
                        <v-row align="center">
                          <v-col cols="auto">
                            <v-checkbox
                              class="mt-0"
                              dense
                              hide-details
                              label="遅刻"
                              value="1"
                              v-model="formData.tags"
                            >
                            </v-checkbox>
                          </v-col>
                          <v-col cols="auto">
                            <v-checkbox
                              class="mt-0"
                              dense
                              hide-details
                              label="早退"
                              value="2"
                              v-model="formData.tags"
                            >
                            </v-checkbox>
                          </v-col>
                          <v-col cols="auto">
                            <v-checkbox
                              class="mt-0"
                              dense
                              hide-details
                              label="その他"
                              value="3"
                              v-model="formData.tags"
                            >
                            </v-checkbox>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="2" class="form-header-1 text-right"
                        >メモ</v-col
                      >
                      <v-col cols="9" class="flex-grow-1">
                        <v-textarea
                          outlined
                          hide-details
                          dense
                          class="select"
                          label="勤怠メモを入力"
                          v-model="formData.memo"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="auto" class="text-center flex-grow-1 mt-4">
                        <v-btn
                          rounded
                          color="#AD4545"
                          class="px-8 mr-4"
                          @click="createAttendance = false"
                          text
                        >
                          <v-icon size="20" color="#AD4545" class="mr-2" left
                            >$trash</v-icon
                          >
                          削除する
                        </v-btn>
                        <v-btn
                          rounded
                          color="#4F55A7"
                          class="white--text px-8"
                          @click="createNewWorktime"
                        >
                          <v-icon size="20" color="white" class="mr-2" left
                            >$attendance</v-icon
                          >
                          勤怠を保存する
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'Create',
  data() {
    return {
      createAttendance: false,
      date: '',
      modal: false,
      modalIn: false,
      modalOut: false,
      timeIn: '',
      timeOut: '',
      formData: {
        user_id: null,
        task_id: null,
        taskMembers: null,
        clock_in: null,
        clock_out: null,
        day_rest: null,
        night_rest: null,
        type_id: null,
        memo: null,
        // resting_minutes: null,
        tags: []
      },
      loading: {
        all: false,
        task: false,
        member: false
      },
      // lunch_rest: null,
      // night_rest: null,
      task_type: [
        {
          id: 1,
          value: 'coating task'
        },
        {
          id: 2,
          value: 'other cleaning task'
        }
      ],
      clockType: [
        { header: '現場 ' },
        { name: '現場勤務（リ）', value: '0' },
        { name: '現場勤務（個）', value: '1' },
        { divider: true },
        { header: 'その他' },
        { name: '運転・調整', value: '2' },
        { name: '参加', value: '3' },
        { name: 'タスク', value: '4' },
        { name: 'その他', value: '5' }
      ]
    }
  },
  created() {
    this.getUsersData()
  },

  methods: {
    async getTaskData() {
      this.$refs.observer.reset()
      this.loading.task = true
      this.formData.task_id = null
      this.formData.taskMembers = null
      let params = {}
      params.paginate = 10
      params.date = this.date
      await this.$store.dispatch('GET_ALL_TASK_BY_DATE', params)
      this.loading.task = false
    },

    async getTaskMembers() {
      this.$refs.observer.reset()
      this.loading.member = true
      let params = {}
      // params.paginate = 10
      params.task_id = this.formData.task_id
      await this.$store.dispatch('GET_TASK_MEMBERS_BY_TASK_ID', params)
      this.formData.taskMembers = this.$store.getters.taskMembers
      this.loading.member = false
    },

    formatDatePicker(e) {
      return dayjs(e).format('D')
    },

    createNewWorktime() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }

        // this.formData.resting_minutes = this.lunch_rest + this.night_rest
        this.formData.clock_in = this.timeIn
        this.formData.clock_out = this.timeOut
        this.$store.dispatch('CREATE_NEW_WORKTIME', this.formData).then(
          response => {
            if (response.data.status == 'success') {
              this.$router.push({
                path: '/work-time/list'
              })
            }
          },
          error => {
            this.$refs.form.reset()
            this.$refs.observer.reset()
            // throw error
          }
        )
      })
    },
    createAttendances() {
      this.createAttendance = true
    },
    async getUsersData() {
      this.loading.all = true
      await this.$store.dispatch('USER_GET_ALL')
      this.loading.all = false
    },
    isNumber(val) {
      if (isNaN(Number(val.key))) {
        return val.preventDefault()
      }
    }
  },
  computed: {
    allTasks() {
      return this.$store.getters.allTasks
    },
    taskMembers() {
      return this.$store.getters.taskMembers
    },
    allUsers() {
      return this.$store.getters.allUsers
    }
  }
}
</script>
<style lang="scss" src="./Create.scss" scoped></style>
